import { Form, withFormik } from 'formik';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';
import { validationMaterialForm } from 'utils/schema';

import { memo, useEffect } from 'react';

import store from 'store';
import { useSelector } from 'react-redux';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { lessonSelectors, lessonThunks } from 'store/ducks/lesson';
import { materialThunks } from 'store/ducks/material';
import { uploadActions, uploadThunks } from 'store/ducks/upload';

import { toast } from 'react-toastify';
import { getNameAndNumber } from 'utils/helpers';

import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';

import { ReactComponent as PlusIcon } from 'theme/icons/plusIcon.svg';
import FileUploader from './FileUploader';
import { articleSelectors, articleThunks } from 'store/ducks/article';
import { FormColumnWrapper } from 'views/common/styledComponents';
import { useParams } from 'react-router-dom';
import { MOBILE_VIEW } from 'utils/constants/common';
import { useTranslation } from 'react-i18next';
import { adminActions } from 'store/ducks/admin';

const MaterialForm = ({
  values,
  isSubmitting,
  setFieldError,
  material,
  selectedLesson,
  selectedPriority,
  selectedMaterialType,
  errors,
  file,
  handleChooseFile,
  onDeleteFile,
  isLoading,
  setFieldValue,
}) => {
  const { id } = useParams();
  const enums = useSelector(dictionarySelectors.getEnums());
  const lessons = useSelector(lessonSelectors.getLessons());
  const articles = useSelector(articleSelectors.getArticles());
  const articlesLoading = useSelector(articleSelectors.articlesLoading());

  const { t } = useTranslation();

  const query = `?q`;

  const isMaterialPresentation =
    selectedMaterialType?.name === 'PRESENTATION' || material?.type.name === 'PRESENTATION';
  const isMaterialArticle = selectedMaterialType?.name === 'ARTICLE' || material?.type.name === 'ARTICLE';

  const isMobile = useMediaQuery(MOBILE_VIEW);

  useEffect(() => {
    material
      ? material?.type.name === 'ARTICLE' && query && store.dispatch(articleThunks.fetchArticles(query))
      : selectedMaterialType?.name === 'ARTICLE' && query && store.dispatch(articleThunks.fetchArticles(query));
  }, [query, selectedMaterialType, material]);

  useEffect(() => {
    return () => {
      store.dispatch(uploadActions.clearItem());
    };
  }, []);

  useEffect(() => {
    lessons?.length === 0 && store.dispatch(lessonThunks.fetchLessons(id));
  }, [lessons, id]);

  useEffect(() => {
    if (file && errors.fileId) {
      setFieldError('fileId', '');
    }
  }, [file, errors, setFieldError]);

  const materialPriorityFromEnum = enums.lessonMaterialPriorities.find((i) => i.name === selectedPriority.name);

  useEffect(() => {
    setFieldValue('lesson', selectedLesson);
    setFieldValue('type', selectedMaterialType);
    setFieldValue('priority', materialPriorityFromEnum);
  }, [setFieldValue, selectedLesson, selectedMaterialType, materialPriorityFromEnum]);

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      sx={{
        borderRadius: 2,
      }}
    >
      <Form id="materialForm" style={{ width: isMobile && '100%' }}>
        <Stack
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            minWidth: isMobile ? '100%' : '420px',
            height: '100%',
            borderRadius: 2,
          }}
        >
          <FormColumnWrapper sx={{ width: '100%', p: isMobile ? 1 : 2, mb: 2 }}>
            <Box textAlign="center">
              <PlusIcon />
              <Typography variant="h5" color="primary.main">
                {material ? material?.type?.label : selectedMaterialType?.label}
              </Typography>
            </Box>

            <FormikAutocomplete
              name="lesson"
              label={t('base.labels.lesson')}
              options={lessons}
              getCustomLabel={getNameAndNumber}
              defaultValue={selectedLesson}
              loading={isLoading}
              /** --- Conditional rendering. If we have selected Lesson
               *  we put the value and don't display the input --- */
              sx={{ display: selectedLesson && 'none' }}
            />
            <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.enterName')} />
            <FormikTextField
              name="number"
              label={t('base.labels.number')}
              placeholder={t('base.placeholders.enterNumber')}
            />
            <FormikAutocomplete
              name="type"
              label={t('base.labels.type')}
              options={enums?.lessonMaterialTypes}
              defaultValue={selectedMaterialType}
              /** --- Conditional rendering. If we have selected material type
               * we put the value and don't display the input --- */
              sx={{ display: selectedMaterialType && 'none' }}
            />
            <FormikAutocomplete
              name="priority"
              label={t('base.labels.priority')}
              options={enums.lessonMaterialPriorities}
              defaultValue={selectedPriority}
              sx={{ display: selectedLesson && 'none' }}
            />
            <FormikTextField
              name="link"
              label={t('base.labels.link')}
              placeholder={t('base.placeholders.pasteLink')}
              sx={{
                display: (isMaterialPresentation || isMaterialArticle) && 'none',
              }}
            />
            <FormikTextField
              name="fileId"
              label={t('base.labels.file')}
              defValue={values?.fileId || ''}
              sx={{
                display: 'none',
              }}
            />

            {isMaterialPresentation && (
              <Box>
                <FileUploader
                  file={file}
                  handleChooseFile={handleChooseFile}
                  filesAccept=".pdf"
                  fileSize="50000"
                  isLoading={isSubmitting}
                  onDeleteFile={onDeleteFile}
                />
                <Typography fontSize={10} color="error" ml={2} textAlign="left">
                  {errors.fileId}
                </Typography>
              </Box>
            )}

            {isMaterialArticle && (
              <FormikAutocomplete
                name="article"
                label={t('base.labels.article')}
                options={articles}
                loading={articlesLoading}
              />
            )}
          </FormColumnWrapper>

          <Button variant="contained" sx={{ width: '100%' }} type="submit" disabled={isSubmitting}>
            {material
              ? `${t('base.buttons.edit')} ${material?.type?.label}`
              : `${t('base.buttons.add')} ${selectedMaterialType?.label}`}
          </Button>
        </Stack>
      </Form>
    </Stack>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ material = {}, selectedLesson }) => ({
      ...material,
      id: material?.id,
      lesson: selectedLesson || null,
      name: material?.name || '',
      number: material?.number || '',
      type: material?.type || null,
      priority: material?.priority || null,
      link: material?.link || '',
      article: material?.article || null,
      fileId: material?.fileId || '',
    }),
    validationSchema: validationMaterialForm,
    handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
      const selectedModuleId = props.selectedModule?.id;
      const selectedLessonId = props.selectedLesson?.id;
      const materialId = props.material?.id;

      if (props.material) {
        store
          .dispatch(materialThunks.updateMaterial(values))
          .then((res) => {
            !res.error && (toast.success(props.t('messages.success.toast.update')), setSubmitting(false)),
              res.meta.requestStatus === 'fulfilled' &&
                store.dispatch(
                  adminActions.replaceMaterial({
                    selectedModuleId,
                    selectedLessonId,
                    materialId,
                    value: res.payload,
                  })
                ),
              setTimeout(() => {
                props.goBack();
              }, 1000);
          })
          .finally(setSubmitting(false));
      } else {
        if (props.selectedMaterialType?.name === 'PRESENTATION') {
          if (!props.file) {
            setFieldError('fileId', props.t('messages.errors.validation.chooseFile'));
            setSubmitting(false);
            return;
          }

          store.dispatch(uploadThunks.uploadFile(props.file)).then((response) => {
            if (response?.meta?.requestStatus === 'fulfilled') {
              const fileId = response?.payload;
              store
                .dispatch(materialThunks.createMaterial({ ...values, fileId }))
                .then((res) => {
                  !res.error && (setSubmitting(false), toast.success(props.t('messages.success.toast.add'))),
                    res.meta.requestStatus === 'fulfilled' &&
                      store.dispatch(
                        adminActions.addMaterial({ selectedModuleId, selectedLessonId, values: res.payload })
                      ),
                    setTimeout(() => {
                      props.goBack();
                    }, 1000);
                })
                .finally(setSubmitting(false));
            }
          });
        } else {
          store
            .dispatch(materialThunks.createMaterial(values))
            .then((res) => {
              !res.error && (setSubmitting(false), toast.success(props.t('messages.success.toast.add'))),
                res.meta.requestStatus === 'fulfilled' &&
                  store.dispatch(adminActions.addMaterial({ selectedModuleId, selectedLessonId, values: res.payload })),
                setTimeout(() => {
                  props.goBack();
                }, 1000);
            })
            .finally(setSubmitting(false));
        }
      }
    },
    enableReinitialize: true,
  })(MaterialForm)
);
